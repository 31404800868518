<template>
  <div>
    <v-dialog v-model="mostrar" persistent width="400">
      <v-card>
        <v-toolbar dark flat dense color="teal lighten-2">
          <v-toolbar-title>Cuenta inactiva </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <strong>Tu cuenta no ha sido activada</strong>
            <br />
            Es necesario realizarlo mediante un email que hemos mandado al
            correo que registraste.<br /><br />
            <i>Recuerda verificar en "Spam" o "Correo no deseado"</i>
          </v-container>
        </v-card-text>
        <v-divider class="mx-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal lighten-2"
            :loading="loading"
            small
            outlined
            @click="reenviarCorreoAction()"
            >Reenviar correo</v-btn
          >
          <v-btn
            color="teal lighten-2"
            :class="{ 'disable-btn': loading }"
            small
            dark
            @click="$emit('cerrar')"
            >Entendido</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { reenviarCorreoActivacion } from "./login.service";

export default {
  name: "cuentaInactiva",
  props: ["mostrar", "idUsuario"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async reenviarCorreoAction() {
      try {
        this.loading = true;
        const serverResponse = await reenviarCorreoActivacion(this.idUsuario);
        this.$validateResponse(serverResponse);
        this.loading = false;
        if (!serverResponse.ok) this.error = true;
        else this.$emit("cerrar");
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
  },
};
</script>
