import { getServerRequest, postServerRequest, putServerRequest } from '@/helpers/serverRequest.helper';
import { store } from '../../store/index';
const httpURL = store.getters.httpURL;

export const loginWithGoogle = async(data) => {
    const config = { headers: {} };
    const url = `${httpURL}/login/google`;
    return await postServerRequest(url, data, config);
}

export const loginUser = async(data) => {
    const config = { headers: {} };
    const url = `${httpURL}/login`;
    return await postServerRequest(url, data, config);
}

export const signInAccount = async(data) => {
    const config = { headers: {} };
    const url = `${httpURL}/signIn`;
    return await putServerRequest(url, data, config);
}

export const reenviarCorreoActivacion = async(idUsuario) => {
    const config = { headers: {} };
    const url = `${httpURL}/activacion/reenvio/${idUsuario}`;
    return await getServerRequest(url, config);
}

export const activateAccount = async(tokenActivacion) => {
    const config = { headers: {} };
    const url = `${httpURL}/activacion/${tokenActivacion}`;
    return await getServerRequest(url, config);
}

export const enviarCorreoRecoveryPass = async(data) => {
    const config = { headers: {} };
    const url = `${httpURL}/restaurarPass/envio`;
    return await postServerRequest(url, data, config);
}

export const validarTokenParaRestablecerPass = async(token) => {
    const config = { headers: {} };
    const url = `${httpURL}/restaurarPass/${token}`;
    return await getServerRequest(url, config);
}

export const restablecerPass = async(data) => {
    const config = { headers: {} };
    const url = `${httpURL}/restaurarPass`;
    return await putServerRequest(url, data, config);
}