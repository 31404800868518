<template>
  <div>
    <form v-on:submit.prevent>
      <v-card flat>
        <v-card-title class="text-center">
          <v-spacer />Iniciar sesión <v-spacer />
        </v-card-title>
        <v-card-text class="text-center" style="padding-bottom: 2px">
          <v-text-field
            v-model="correo"
            prepend-inner-icon="mdi-at"
            name="login_correo"
            label="Correo"
            type="text"
            outlined
            :disabled="loading"
            :error-messages="correoErrors"
            @input="$v.correo.$touch()"
            @blur="$v.correo.$touch()"
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            id="password"
            prepend-inner-icon="mdi-lock"
            name="login_password"
            label="Contraseña"
            type="password"
            outlined
            :disabled="loading"
            :error-messages="passwordErrors"
            style="margin-bottom: 2px;"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          >
          </v-text-field>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <v-btn
            type="submit"
            color="deep-purple lighten-1"
            @click="submit()"
            block
            dark
            :loading="loading"
          >
            Iniciar sesión
          </v-btn>
        </v-card-text>
        <v-card-text>
          <formularioRegistroComponent />
          <cuentaInactivaComponent
            v-if="activaError"
            :idUsuario="idUsuario"
            :mostrar="activaError"
            @cerrar="activaError = false"
          />
          <recoveryPassComponent style="padding-top: 10px;" />
        </v-card-text>
      </v-card>
    </form>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { loginUser } from "./login.service";

import recoveryPassComponent from "./recoveryPass";
import formularioRegistroComponent from "./registroForm";
import cuentaInactivaComponent from "./cuentaInactiva";

export default {
  components: {
    recoveryPassComponent,
    formularioRegistroComponent,
    cuentaInactivaComponent,
  },
  mixins: [validationMixin],
  computed: {
    correoErrors() {
      const errors = [];
      if (!this.$v.correo.$dirty) return errors;
      !this.$v.correo.required && errors.push("Campo requerido.");
      !this.$v.correo.email && errors.push("Correo no válido.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength && errors.push("Mínimo 5 caracteres.");
      !this.$v.password.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    correo: { required, email },
    password: { required, minLength: minLength(5) },
  },
  data() {
    return {
      loading: false,
      correo: "",
      password: "",
      errorMessage: null,
      activaError: false,
      idUsuario: null,
      mailEnviado: false,
      recaptchaToken: null,
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      this.errorMessage = null;
      if (!this.$v.correo.$invalid && !this.$v.password.$invalid) {
        try {
          const sendData = {
            correo: this.correo,
            password: this.password,
          };
          this.loading = true;
          const serverResponse = await loginUser(sendData);

          this.$validateResponse(serverResponse);
          this.loading = false;
          if (!serverResponse.ok) {
            if (serverResponse.inactiva) {
              this.idUsuario = serverResponse.idUsuario;
              this.activaError = true;
            } else this.errorMessage = serverResponse.mensaje;
          } else {
            localStorage.setItem("hiperToken", serverResponse.sessionToken);
            this.$router.push("/dashboard");
          }
        } catch (error) {
          this.loading = false;
          this.$appError(error.mensaje);
        }
      }
    },
  },
};
</script>

<style>
.error-message {
  color: brown;
  padding-bottom: 10px;
}
</style>
