<template>
  <div class="text-center">
    <v-dialog v-model="mostrarFormulario" width="350">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :loading="loading"
          v-bind="attrs"
          v-on="on"
          block
          link
          plain
          small
          color="blue darken-4"
        >
          Recuperar contraseña
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark flat dense color="blue darken-4">
          <v-toolbar-title>Recuperar contraseña</v-toolbar-title>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="12">
              <div>
                Ingresa tu correo para enviar enlace de recuperación:
                <br /><br />
              </div>
              <v-text-field
                v-model="correo"
                prepend-inner-icon="mdi-at"
                name="login_correo"
                label="Correo"
                type="text"
                outlined
                :disabled="loading || correoEnviado"
                :error-messages="correoErrors"
                @input="validarCorreo()"
                @blur="validarCorreo()"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-text v-if="correoEnviado">
          Se ha enviado un correo de recuperación de contraseña. <br />
        </v-card-text>

        <v-divider class="mx-3"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :disabled="loading"
            @click="mostrarFormulario = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="correoErrors.length > 0 || correoEnviado"
            @click="recuperarPassword()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { enviarCorreoRecoveryPass } from "./login.service";

export default {
  name: "recoveryPass",
  mixins: [validationMixin],
  validations: {
    correo: { required, email },
  },
  data() {
    return {
      mostrarFormulario: false,
      loading: false,
      correoEnviado: false,
      correo: "",
      correoErrors: [],
    };
  },
  methods: {
    validarCorreo() {
      const errors = [];
      !this.$v.correo.required && errors.push("Campo requerido.");
      !this.$v.correo.email && errors.push("Correo no válido.");
      this.correoErrors = errors;
    },
    async recuperarPassword() {
      this.$v.correo.$touch();
      if (this.correoErrors.length == 0) {
        try {
          const sendData = { correo: this.correo };
          this.loading = true;
          const serverResponse = await enviarCorreoRecoveryPass(sendData);
          this.$validateResponse(serverResponse);
          this.loading = false;
          if (!serverResponse.ok) {
            this.correoErrors = [serverResponse.mensaje];
          } else {
            this.correoEnviado = true;
          }
        } catch (error) {
          this.loading = false;
          this.$appError(error.mensaje);
        }
      }
    },
  },
};
</script>
