<template>
  <v-app>
    <v-container fluid class="fill-height" id="login-bg">
      <v-row align="center" justify="center">
        <v-col cols="11" sm="8" md="5" lg="4">
          <v-card class="elevation-12">
            <v-card-text>
              <v-img
                :src="require('@/assets/logo-hiper-200.jpg')"
                max-height="150"
                contain
              >
              </v-img>
              <!-- <externalLoginComponent /> -->
              <!-- <v-divider></v-divider> -->
              <formularioLoginComponent />
            </v-card-text>
            <v-card-text class="text-center">
              <v-divider></v-divider>
              <mainFooterComponent />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <appNotificationComponent />
    </v-container>
  </v-app>
</template>

<script>

import formularioLoginComponent from "./loginForm";
// import externalLoginComponent from "./externalLogin";
import mainFooterComponent from "@/template/mainFooter";
import appNotificationComponent from "@/template/appNotification";

export default {
  name: "loginView",
  components: {
    formularioLoginComponent,
    // externalLoginComponent,
    mainFooterComponent,
    appNotificationComponent
  },
  data() {
    return {
      loading: false,
      role: null,
      idUsuario: null,
      mailEnviado: false,
      recaptchaToken: null
    };
  },
  created() {
    const tokenLocal = localStorage.getItem("hiperToken");
    if (tokenLocal) {
      this.$store.dispatch("setSessionToken", tokenLocal);
      this.$router.push("/dashboard");
    }
  },
  methods: {},
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
#login-bg {
  height: 100vh;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
