<template>
  <div class="text-center">
    <v-dialog v-model="mostrarFormulario" persistent width="350">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :loading="loading"
          v-bind="attrs"
          v-on="on"
          color="teal lighten-2"
          block
          dark
        >
          Registrarme
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark flat dense color="teal lighten-2">
          <v-toolbar-title>Registro</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="text-center" style="padding-bottom: 2px;">
          <v-container>
            <v-text-field
              v-model.trim="nombre"
              prepend-inner-icon="mdi-account"
              name="login_nombre"
              label="Nombre"
              type="text"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.nombre"
              @input="validarNombre()"
              @blur="validarNombre()"
            >
            </v-text-field>
            <v-text-field
              v-model.trim="correo"
              prepend-inner-icon="mdi-at"
              name="login_correo"
              label="Correo"
              type="text"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.correo"
              @input="validarCorreo()"
              @blur="validarCorreo()"
            >
            </v-text-field>
            <v-text-field
              v-model="password1"
              id="password1"
              prepend-inner-icon="mdi-lock"
              name="login_password1"
              label="Contraseña"
              type="password"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.password1"
              @input="validarPassword1()"
              @blur="validarPassword1()"
            >
            </v-text-field>
            <v-text-field
              v-model="password2"
              id="password2"
              prepend-inner-icon="mdi-lock"
              name="login_password2"
              label="Repite contraseña"
              type="password"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.password2"
              @input="validarPassword2()"
              @blur="validarPassword2()"
            >
            </v-text-field>

            <v-checkbox v-model="terminos">
              <template v-slot:label>
                <div>
                  He leído y acepto los
                  <a href="javascript:void(0)" class="flat-link" @click.stop
                    >Téminos y condiciones</a
                  >
                  y el
                  <a href="javascript:void(0)" class="flat-link" @click.stop
                    >Aviso de privacidad</a
                  >
                </div>
              </template>
            </v-checkbox>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal lighten-2"
            outlined
            small
            :disabled="loading"
            @click="mostrarFormulario = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="teal lighten-2"
            dark
            small
            :loading="loading"
            :class="{ 'disable-btn': !terminos }"
            @click="registrarUsuario()"
          >
            Registrarme
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <registroExitosoComponent
      v-if="registroExitoso"
      :mostrar="registroExitoso"
      @cerrar="registroExitoso = false"
    />
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, sameAs, minLength, email } from "vuelidate/lib/validators";

import registroExitosoComponent from "./registroExitoso";

import { signInAccount } from "./login.service";

export default {
  mixins: [validationMixin],
  components: { registroExitosoComponent },
  validations: {
    nombre: { required, minLength: minLength(5) },
    correo: { required, email },
    password1: { required, minLength: minLength(5) },
    password2: {
      required,
      sameAs: sameAs("password1"),
      minLength: minLength(5),
    },
  },
  data() {
    return {
      mostrarFormulario: false,
      registroExitoso: false,
      loading: false,
      nombre: "",
      correo: "",
      password1: "",
      password2: "",
      terminos: false,
      errors: {
        nombre: [],
        correo: [],
        password1: [],
        password2: [],
      },
    };
  },
  methods: {
    validarNombre() {
      const errors = [];
      this.$v.nombre.$touch();
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      !this.$v.nombre.minLength && errors.push("Mínimo 5 caracteres.");
      this.errors.nombre = errors;
    },
    validarCorreo() {
      const errors = [];
      this.$v.correo.$touch();
      if (!this.$v.correo.$dirty) return errors;
      !this.$v.correo.required && errors.push("Campo requerido.");
      !this.$v.correo.email && errors.push("Correo no válido.");
      this.errors.correo = errors;
    },
    validarPassword1() {
      const errors = [];
      this.$v.password1.$touch();
      if (!this.$v.password1.$dirty) return errors;
      !this.$v.password1.required && errors.push("Campo requerido.");
      !this.$v.password1.minLength && errors.push("Mínimo 5 caracteres.");
      this.errors.password1 = errors;
    },
    validarPassword2() {
      const errors = [];
      this.$v.password2.$touch();
      if (!this.$v.password2.$dirty) return errors;
      !this.$v.password2.required && errors.push("Campo requerido.");
      !this.$v.password2.minLength && errors.push("Mínimo 5 caracteres.");
      !this.$v.password2.sameAs &&
        errors.push("Las contraseñas deben ser iguales.");
      this.errors.password2 = errors;
    },
    formularioValido() {
      this.validarNombre();
      this.validarCorreo();
      this.validarPassword1();
      this.validarPassword2();
      if (this.errors.nombre.length > 0) return false;
      if (this.errors.correo.length > 0) return false;
      if (this.errors.password1.length > 0) return false;
      if (this.errors.password2.length > 0) return false;
      return true;
    },
    async registrarUsuario() {
      if (this.formularioValido()) {
        try {
          const sendData = {
            nombre: this.nombre,
            correo: this.correo,
            password: this.password1,
          };
          this.loading = true;
          const serverResponse = await signInAccount(sendData);

          this.$validateResponse(serverResponse);
          this.loading = false;
          if (!serverResponse.ok) {
            if (serverResponse.nombreError)
              this.errors.nombre.push(serverResponse.mensaje);
            else if (serverResponse.correoError)
              this.errors.correo.push(serverResponse.mensaje);
            else if (serverResponse.passwordError)
              this.errors.password1.push(serverResponse.mensaje);
            else this.$appWarning(serverResponse.mensaje);
          } else {
            this.nombre = "";
            this.correo = "";
            this.password1 = "";
            this.password2 = "";
            this.mostrarFormulario = false;
            this.registroExitoso = true;
          }
        } catch (error) {
          this.loading = false;
          this.$appError(error.mensaje);
        }
      }
    },
  },
};
</script>

<style>
.disable-btn {
  pointer-events: none;
  opacity: 0.2;
}
</style>
