<template>
  <div>
    <v-dialog v-model="mostrar" persistent width="400">
      <v-card>
        <v-toolbar dark flat dense color="teal lighten-2">
          <v-toolbar-title>¡Registro exitoso! 😃</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <strong>Sólo te falta un paso más: </strong>
                <br />
                Se ha enviado un correo de activación. <br>
                Una vez activada tu cuenta
                podrás comenzar con tu camino a ser un Hiperlector. <br><br>
                <i>Recuerda verificar en "Spam" o "Correo no deseado"</i>
            </v-container>
        </v-card-text>
        <v-divider class="mx-3"></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
          <v-btn color="teal lighten-2" small outlined @click="$emit('cerrar')"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "registroExitoso",
  props: ["mostrar"],
  data() {
    return {};
  },
};
</script>
